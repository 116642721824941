import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`A divider is used to provide visual separation of different content. Dividers can be applied vertically or horizontally.
Divider is a visual cue to help to convey content separations and groupings.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/div-1.svg",
      "alt": "anatomy divider guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Line :`}</strong>{` a divider is a thin line placed either horizontally or vertically between elements.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Dividers are a subtle way to separate content into groups, sections, options, or parts. Many times, negative space will accomplish the same goal, so use them sparingly, as excessive use might overwhelm a user.`}</p>
    <p><strong parentName="p">{`Common places divider appear:`}</strong>{` Between elements or section`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> Use to represent thematic breaks between elements.</li>
      <li> Need to divide sections of content from each other. </li>
      <li> Use to separate content into clear groups. </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> Avoid using for presentational purposes only. </li>
      <li> Don’t use strong colors in dividers. </li>
      <li> Don’t use dividers for replacing card components. </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`A divider pauses the screen reader from continuing to read content in a new section. Users need to swipe forward to move past the divider and indicate the start of a new section of content.
Dividers should be used sparingly and only if content cannot be separated by white space. Divides content into clear groups.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : Full width & thin" caption="Use Dividers to separate collections of content or create the appearance of containers" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/div-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Custom width & thick" caption="Inset Divider in a way that causes it to be free-floating or separated from content" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/divider/div-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`Divider has no content guidelines itself, but plays a role informatively and semantically around how content should be grouped and separated. Such as :`}</p>
    <ul>
      <li parentName="ul">{`Divides content into clear groups`}</li>
      <li parentName="ul">{`A divider is informative so must be perceivable to all users`}</li>
      <li parentName="ul">{`Dividers should be used sparingly and only if content cannot be separated by white space`}</li>
      <li parentName="ul">{`A screen reader experience needs to mirror the visual experience to let the user know that a new “section” has been started after a divider is visually presented`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      